/* CloseButton.css */

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 20px;
  cursor: pointer;
  color: #333; /* Adjusted for better visibility */
  z-index: 1000; /* Ensure it's above other content */
}

/* Ensures that the button is only shown on mobile within the Sidebar */
.sidebar .close-button {
  display: none; /* Hide by default */
}

/* Specific styling when in a form container to ensure it's always visible */
.form-container .close-button {
  display: block; /* Always show in form containers */
}

@media (max-width: 480px) {
  /* Only show the close button in the sidebar for mobile devices */
  .sidebar .close-button {
    display: block;
  }
}
