/* SuggestionGenerator.css */

.suggestion-form-container {
    min-height: auto;
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
}

.suggestion-form {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin: auto;
    max-width: 800px;
}

.generate-button, .clear-button {
    margin-top: 20px;
}

.suggestions-list {
    margin-top: 20px;
    text-align: left;
}

.suggestions-list .MuiTypography-root {
    cursor: pointer;
    transition: all 0.3s ease;
}

.suggestion-link, .image-link {
    color: #1a0dab;
    text-decoration: none;
    cursor: pointer;
}

.suggestion-link:hover, .image-link:hover {
    text-decoration: underline;
}

/* Responsive adjustments for medium devices */
@media screen and (max-width: 992px) {
    .suggestion-form-container {
        margin-top: 120px;
        margin-left: 0;
    }
    .suggestion-form {
        max-width: calc(100% - 40px);
    }
}

/* Adjustments for smaller screens */
@media screen and (max-width: 768px) {
    .suggestion-form-container {
        margin-top: 120px;
    }
    .suggestion-form {
        max-width: calc(100% - 40px);
    }
}

/* Further adjustments for very small screens */
@media screen and (max-width: 576px) {
    .suggestion-form-container {
        margin-top: 120px;
    }
    .suggestion-form {
        max-width: calc(100% - 20px);
    }
}

/* Additional adjustments for larger screens */
@media screen and (min-width: 992px) {
    .suggestion-form-container {
        margin-left: calc((100vw - 1392px) / 2 + 120px);
    }
    .suggestion-form {
        max-width: calc(1392px - 300px);
    }
}

/* Custom styles for aligning select text to left */
.MuiInputBase-root .MuiSelect-select {
    text-align: left;
    text-align-last: left;
}

/* Ensure the dropdown arrow and clear icon remain aligned properly */
.MuiInputBase-root .MuiSvgIcon-root {
    position: absolute;
    right: 12px; /* Adjust based on your layout and padding */
}
