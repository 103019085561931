/* HomePage.css */

.home-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  padding-top: 60px;
  padding-bottom: 10px;
  width: 100%;
  overflow-x: hidden;
}

.card-container {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
}

.homepage-logo {
  width: 100px;
  height: auto;
}

.title {
  font-size: 0.9rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 6px;
}

.title-link {
  text-decoration: none;
  color: inherit;
}

.input-field {
  margin-bottom: 5px;
  border-radius: 5px;
  font-size: 1rem;
}

@media (max-width: 480px) {
  .input-field {
    font-size: 0.875rem; /* Ensure this is smaller than 16px */
  }

  .MuiInputBase-input {
    font-size: 0.875rem; /* Ensure this is smaller than 16px */
  }
}

.buttons-container {
  display: flex;
  justify-content: center;
  margin-top: 6px;
  gap: 2px;
}

.action-button {
  padding: 4px 12px;
  font-size: 0.8rem;
  font-weight: 500;
}

.suggestions-list {
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  width: 100%;
}

.submission-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  width: 100%;
  max-width: 400px;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
  padding-bottom: 5px;
  border: 1px solid #d3d3d3; /* Add this line for the border */
}

.submission-container:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
}

.submission-spacing {
  height: 16px;
}

.submission-link {
  display: block;
  text-decoration: none;
  color: inherit;
}

.submission-image {
  width: 100%;
  max-height: 140px;
  object-fit: contain;
  border-radius: 6px 6px 0 0;
}

.submission-title {
  margin: 3px 0;
  font-size: 0.7rem;
  font-weight: 500;
  color: blue !important;
  word-wrap: break-word; /* Ensure text wraps within the container */
}

/* Ad styles */
.ad-item {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  width: 100%;
  max-width: 400px;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
  padding-bottom: 5px;
  border: 1px solid #d3d3d3;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
}

.ad-logo {
  width: 200px;
  height: auto;
  margin-bottom: 10px;
}

.ad-link {
  color: blue;
  text-decoration: underline;
  font-size: 1rem; /* Adjusted font size */
  font-weight: 500; /* Added font weight */
}

/* Share this App styles */
.share-container {
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  text-align: center;
  max-width: 300px; /* Ensure the container doesn't exceed the box */
  margin: auto; /* Center the container */
}

.share-title {
  font-size: 1rem;
  margin-bottom: 4px; /* Adjust the margin */
  margin-top: -4px; /* Move the text up slightly */
  font-weight: bold; /* Make the text bold */
}

.share-buttons {
  display: flex;
  justify-content: space-around; /* Adjust spacing to fit within the container */
  gap: 5px; /* Adjust gap to fit within the container */
  flex-wrap: wrap; /* Allow buttons to wrap to the next line */
}

.share-buttons > * {
  flex: 1 1 30%; /* Ensure buttons take up 30% of the row and wrap appropriately */
  max-width: 60px; /* Ensure buttons do not exceed a reasonable width */
  margin: 5px 0; /* Add margin to separate buttons vertically */
}

@media screen and (max-width: 375px) {
  .home-page-container {
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px; /* Adjust padding to prevent overlap with header */
    padding-bottom: 80px; /* Adjust padding to prevent overlap with footer */
  }

  .card-container {
    max-width: 90%; /* Adjust to make the card slightly wider */
    padding: 6px;
  }

  .input-field {
    font-size: 0.75rem; /* Adjust font size for input fields */
    margin-bottom: 3px; /* Reduce margin bottom of input fields */
  }

  .action-button {
    font-size: 0.65rem; /* Adjust font size for action buttons */
    padding: 2px 6px; /* Adjust padding for action buttons */
  }

  .title {
    font-size: 0.8rem; /* Adjust font size for title */
    margin-bottom: 4px; /* Reduce margin bottom of the title */
  }

  .buttons-container {
    margin-top: 4px;
  }

  .submission-container {
    max-width: 90%; /* Adjust to make the card slightly wider */
  }

  .submission-image {
    max-height: 100px; /* Further reduce max height of images */
  }

  .submission-title {
    font-size: 0.65rem; /* Adjust font size for submission titles */
  }

  .ad-logo {
    width: 180px; /* Adjust width of ad logo */
    margin-bottom: 8px; /* Reduce margin bottom of ad logo */
  }

  .ad-link {
    font-size: 0.8rem; /* Adjust font size for ad link */
  }

  .share-title {
    font-size: 0.9rem; /* Adjust font size for share title */
  }

  .share-buttons {
    gap: 3px; /* Adjust gap for share buttons */
  }

  .share-buttons > * {
    width: 28px; /* Reduce size of share buttons */
    height: 28px; /* Reduce size of share buttons */
  }
}

/* iPhone XR, iPhone 12 Pro, iPhone 14 Pro Max */
@media screen and (min-width: 376px) and (max-width: 430px) {
  .home-page-container {
    margin-left: auto;
    margin-right: auto;
  }
}

/* Pixel 7, Samsung Galaxy S8+, Samsung Galaxy S20 Ultra */
@media screen and (min-width: 431px) and (max-width: 600px) {
  .home-page-container {
    margin-left: auto;
    margin-right: auto;
  }

  .share-container {
    padding: 8px; /* Adjust padding to fit within the container */
    max-width: 280px; /* Ensure the container doesn't exceed the box */
  }

  .share-title {
    font-size: 0.8rem; 
  }

  .share-buttons {
    gap: 2px; /* Further adjust gap for share buttons */
  }

  .share-buttons > * {
    width: 24px; /* Reduce size of share buttons */
    height: 24px; /* Reduce size of share buttons */
  }
}

/* iPad Mini */
@media screen and (min-width: 601px) and (max-width: 768px) {
  .home-page-container {
    margin-left: auto;
    margin-right: auto;
  }
}

/* iPad Air */
@media screen and (min-width: 769px) and (max-width: 820px) {
  .home-page-container {
    margin-left: auto;
    margin-right: auto;
  }
}

/* Surface Duo */
@media screen and (min-width: 821px) and (max-width: 1368px) {
  .home-page-container {
    margin-left: auto;
    margin-right: auto;
  }
}

/* Galaxy Z Fold 5, Samsung Galaxy A51/71, Nest Hub, Nest Hub Max */
@media screen and (min-width: 1369px) and (max-width: 1920px) {
  .home-page-container {
    margin-left: auto;
    margin-right: auto;
  }

  .share-buttons {
    gap: 8px; /* Increase the gap between share buttons */
  }

  .share-buttons > * {
    width: 28px; /* Reduce size of share buttons */
    height: 28px; /* Reduce size of share buttons */
  }
}

.copy-link-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.copy-icon {
  width: 32px;
  height: 32px;
}

.copy-success {
  font-size: 0.8rem;
  color: green;
  margin-top: 5px;
}

@media (max-width: 768px) {
  .home-page-container {
    margin-left: 0;
    padding: 0;
    margin-top: 100px;
  }

  .card-container {
    max-width: 90%;
  }

  .submission-container {
    max-width: 100%;
  }

  .submission-image {
    max-height: 120px;
  }
}

@media (min-width: 769px) and (max-width: 820px) {
  .home-page-container {
    margin-top: 60px;
  }
}

@media (min-width: 821px) and (max-width: 912px) {
  .home-page-container {
    justify-content: center;
    margin-top: 60px;
    margin-left: 70px;
  }

  .card-container {
    max-width: 60%;
  }
}

@media (min-width: 913px) and (max-width: 1368px) {
  .home-page-container {
    margin-top: 80px;
  }
}

@media (max-width: 480px) {
  .home-page-container {
    margin-top: 80px;
  }

  .card-container {
    max-width: 90%;
  }
}

.action-button {
  background: #1877f2;
  color: white;
  padding: 4px 8px;
  border: none;
  outline: none;
  transition: background-color 0.2s, transform 0.1s;
  border-radius: 6px;
  box-shadow: none;
  font-weight: bold;
}

.action-button:hover,
.action-button:focus {
  background: #165cd8;
  transform: translateY(-2px);
}

.action-button:active {
  background: #0a3c85;
  transform: translateY(1px);
}

.MuiCard-root {
  box-shadow: none;
  border-radius: 12px;
  margin: auto;
  padding: 10px;
  border: 1px solid #d3d3d3;
  width: 100%;
  max-width: 400px;
}

.MuiCardContent-root {
  padding: 14px;
}

.MuiTextField-root {
  margin-bottom: 5px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  background-color: #ffffff;
  font-size: 1rem;
}

.typography-h5 {
  font-size: 0.75rem;
  font-weight: 600;
}

.generate-button {
  background: linear-gradient(45deg, #fe6b8b 30%, #ff8e53 90%);
  color: white;
  margin-right: 8px;
}

.clear-button {
  background-color: transparent;
  color: #757575;
}

.suggestions-list {
  margin-top: 6px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.submission-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  width: 100%;
  text-align: center;
}

.submission-container img {
  width: auto;
  max-width: 100%;
  max-height: 280px;
  height: auto;
  object-fit: contain;
  margin: 0 auto;
}

.submission-container:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.submission-container:hover img {
  opacity: 0.9;
}

.text-overlay {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 8px;
  width: 100%;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.submission-container:hover .text-overlay {
  opacity: 1;
}

@media (max-width: 768px) {
  .home-page-container {
    margin-left: 0;
    padding: 0;
  }

  .MuiCard-root {
    max-width: 90%;
  }

  .submission-container {
    max-width: 100%;
  }

  .submission-container img {
    max-height: 250px;
  }
}

@media (max-width: 480px) {
  .MuiCard-root {
    max-width: 90%;
  }

  .suggestions-list {
    grid-template-columns: 1fr;
  }
}

.buttons-container {
  display: flex;
  justify-content: center;
  margin-top: 8px;
  gap: 6px;
}

.buttons-container.two-buttons .action-button {
  margin: 0 2px;
}

@media (max-width: 480px) {
  .buttons-container {
    justify-content: center;
  }

  .action-button {
    max-width: 100px;
    flex: none;
    margin: 0 5px;
  }
}

.loading-indicator {
  display: flex;
  justify-content: center;
}

.success-message,
.error-message {
  font-size: 1rem;
  margin-top: 10px;
  text-align: center;
}

.MuiInputBase-root .MuiSelect-select {
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
}

.MuiInput-underline:before {
  border-bottom: 2px solid rgba(0, 0, 0, 0.42);
}

.MuiMenuItem-root {
  color: rgba(0, 0, 0, 0.54);
  font-size: 1rem;
}

@media (min-width: 1024px) {
  .home-page-container {
    margin: 0 auto;
  }

  .card-container {
    max-width: 500px;
    margin: 0 auto;
  }
}

@media (min-width: 1440px) {
  .home-page-container {
    margin: 0 auto;
  }

  .card-container {
    max-width: 500px;
    margin: 0 auto;
  }
}

@media (min-width: 1920px) {
  .home-page-container {
    margin: 0 auto;
  }

  .card-container {
    max-width: 500px;
    margin: 0 auto;
  }
}

/* Center the container on specific devices */
/* Adjustments for iPhone SE */
@media screen and (max-width: 375px) {
  .home-page-container {
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px; /* Adjust padding to prevent overlap with header */
    padding-bottom: 80px; /* Adjust padding to prevent overlap with footer */
  }

  .card-container {
    max-width: 90%; /* Adjust to make the card slightly wider */
    padding: 6px;
  }

  .input-field {
    font-size: 0.75rem; /* Adjust font size for input fields */
    margin-bottom: 3px; /* Reduce margin bottom of input fields */
  }

  .action-button {
    font-size: 0.65rem; /* Adjust font size for action buttons */
    padding: 2px 6px; /* Adjust padding for action buttons */
  }

  .title {
    font-size: 0.8rem; /* Adjust font size for title */
    margin-bottom: 4px; /* Reduce margin bottom of the title */
  }

  .buttons-container {
    margin-top: 4px;
  }

  .submission-container {
    max-width: 90%; /* Adjust to make the card slightly wider */
  }

  .submission-image {
    max-height: 100px; /* Further reduce max height of images */
  }

  .submission-title {
    font-size: 0.65rem; /* Adjust font size for submission titles */
  }

  .ad-logo {
    width: 180px; /* Adjust width of ad logo */
    margin-bottom: 8px; /* Reduce margin bottom of ad logo */
  }

  .ad-link {
    font-size: 0.8rem; /* Adjust font size for ad link */
  }

  .share-title {
    font-size: 0.9rem; /* Adjust font size for share title */
  }

  .share-buttons {
    gap: 3px; /* Adjust gap for share buttons */
  }

  .share-buttons > * {
    width: 28px; /* Reduce size of share buttons */
    height: 28px; /* Reduce size of share buttons */
  }
}

/* iPhone XR, iPhone 12 Pro, iPhone 14 Pro Max */
@media screen and (min-width: 376px) and (max-width: 430px) {
  .home-page-container {
    margin-left: auto;
    margin-right: auto;
  }
}

/* Pixel 7, Samsung Galaxy S8+, Samsung Galaxy S20 Ultra */
@media screen and (min-width: 431px) and (max-width: 600px) {
  .home-page-container {
    margin-left: auto;
    margin-right: auto;
  }

  .share-container {
    padding: 8px; /* Adjust padding to fit within the container */
    max-width: 280px; /* Ensure the container doesn't exceed the box */
  }

  .share-title {
    font-size: 0.8rem; /* Further adjust font size for share title */
  }

  .share-buttons {
    gap: 2px; /* Further adjust gap for share buttons */
  }

  .share-buttons > * {
    width: 24px; /* Reduce size of share buttons */
    height: 24px; /* Reduce size of share buttons */
  }
}

/* iPad Mini */
@media screen and (min-width: 601px) and (max-width: 768px) {
  .home-page-container {
    margin-left: auto;
    margin-right: auto;
  }
}

/* iPad Air */
@media screen and (min-width: 769px) and (max-width: 820px) {
  .home-page-container {
    margin-left: auto;
    margin-right: auto;
  }
}

/* Surface Duo */
@media screen and (min-width: 821px) and (max-width: 1368px) {
  .home-page-container {
    margin-left: auto;
    margin-right: auto;
  }
}

/* Galaxy Z Fold 5, Samsung Galaxy A51/71, Nest Hub, Nest Hub Max */
@media screen and (min-width: 1369px) and (max-width: 1920px) {
  .home-page-container {
    margin-left: auto;
    margin-right: auto;
  }

  .share-buttons {
    gap: 8px; /* Increase the gap between share buttons */
  }

  .share-buttons > * {
    width: 28px; /* Reduce size of share buttons */
    height: 28px; /* Reduce size of share buttons */
  }
}

/* Adjust margins to shift the container slightly to the left on Nest Hub and Nest Hub Max */
/* Nest Hub */
@media screen and (min-width: 1024px) and (max-width: 1024px) and (min-height: 600px) and (max-height: 600px) {
  .home-page-container {
    margin-left: auto;
    margin-right: auto;
    padding-top: 40px; /* Adjust padding to prevent overlap with footer */
  }

  .card-container {
    max-width: 98%; /* Further increase the width of the card container */
    padding: 8px; /* Adjust padding for the card container */
  }

  .input-field {
    font-size: 0.75rem; /* Further reduce font size for input fields */
  }

  .action-button {
    font-size: 0.65rem; /* Adjust font size for action buttons */
    padding: 2px 6px; /* Adjust padding for buttons */
  }

  .suggestions-list {
    max-width: 98%; /* Further increase the width of the suggestions list */
  }

  .submission-container {
    max-width: 98%; /* Further increase the width of the submission container */
    padding-bottom: 4px; /* Adjust padding for the submission container */
  }

  .submission-image {
    max-height: 100px; /* Reduce the max height of the images */
  }

  .submission-title {
    font-size: 0.65rem; /* Adjust font size for submission titles */
  }
}

/* Asus Zenbook Fold */
@media screen and (min-width: 853px) and (max-width: 1280px) and (min-height: 800px) {
  .home-page-container {
    margin-left: 100px; /* Increase the value to shift further to the right */
  }
}

/* Nest Hub Max */
@media screen and (min-width: 1280px) and (max-width: 1280px) and (min-height: 800px) and (max-height: 800px) {
  .home-page-container {
    margin-left: 200px;
  }

  .card-container {
    max-width: 420px; /* Increase the width of the container */
  }

  .submission-container {
    max-width: 420px; /* Increase the max-width of submission containers */
  }
}
  
/* Center the container on iPad Pro and Surface Pro 7 by shifting to the right */
/* iPad Pro */
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .home-page-container {
    margin-top: 20px; /* Adjust this value to move the container down as needed */
    margin-left: 150px;
  }
}
  
/* Surface Pro 7 */
@media screen and (min-width: 912px) and (max-width: 1368px) {
  .home-page-container {
    margin-left: 150px;
  }
}
  
@media (min-width: 1300px) {
  .home-page-container {
    margin-top: 40px;
    margin-left: 200px;
  }

  .card-container {
    max-width: 500px;
  }
}
  
@media (min-width: 1264px) {
  .home-page-container {
    padding-top: 60px; /* Adjust this value to move the container up as needed */
  }

  .card-container {
    max-width: 420px; /* Increase the width of the container */
    padding: 6px; /* Reduce the padding */
  }

  .input-field {
    font-size: 0.75rem; /* Further reduce the font size of form elements */
    margin-bottom: 3px; /* Reduce the margin bottom of input fields */
  }

  .action-button {
    font-size: 0.65rem; /* Further reduce the font size of buttons */
    padding: 2px 6px; /* Adjust the padding of buttons */
  }

  .title {
    font-size: 0.8rem; /* Reduce the font size of the title */
    margin-bottom: 4px; /* Reduce the margin bottom of the title */
  }

  .buttons-container {
    margin-top: 4px; /* Reduce the margin top of the buttons container */
  }

  .submission-container {
    max-width: 420px; /* Increase the max-width of submission containers */
  }

  .submission-image {
    max-height: 120px; /* Reduce the max height of submission images */
  }

  .submission-title {
    font-size: 0.65rem; /* Reduce the font size of submission titles */
  }

  .ad-logo {
    width: 180px; /* Reduce the width of the ad logo */
    margin-bottom: 8px; /* Reduce the margin bottom of the ad logo */
  }

  .ad-link {
    font-size: 1rem; /* Adjust font size to match submission-title */
    font-weight: 500; /* Make the text bold */
  }
}

.messenger-share-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;
}
