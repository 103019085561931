/* ManagePosts.css */

.post-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .post-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e7e7e7;
  }
  
  .post-thumbnail {
    width: 60px; /* Thumbnail size */
    height: 60px;
    object-fit: cover;
    margin-right: 15px;
    border-radius: 4px;
  }
  
  .post-title {
    margin-right: auto;
    color: #4267B2;
    text-decoration: none;
  }
  
  .post-title:hover {
    text-decoration: underline;
  }
  

  