/* InfoPage.css */

.info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 800px;
    margin: 80px auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border: 2px solid #000; /* Solid black border */
}

.info-container section {
    margin-bottom: 20px;
}

.info-container h1, 
.info-container h2 {
    color: #333;
    margin-bottom: 10px;
}

.info-container p,
.info-container li {
    color: #666;
    line-height: 1.6;
    margin-bottom: 10px;
}

.welcome-section h1 {
    color: #0275d8;
}

.terms-of-use, 
.disclaimer-section, 
.claims-section, 
.miscellaneous-section {
    background: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.info-container, 
.info-container h1, 
.info-container h2, 
.info-container h3, 
.info-container p, 
.info-container ul, 
.info-container li {
    text-align: left;
}

.info-container h1 {
    font-size: 2rem;
}

.info-container h2 {
    font-size: 1.75rem;
}

.info-container p,
.info-container li {
    font-size: 1rem;
}

/* Adjustments for medium screens (less than 992px) */
@media screen and (max-width: 992px) {
    .info-container {
        margin: 180px 20px 20px; /* Increased from 120px to 180px */
    }
}

/* Adjustments for small screens (less than 768px) */
@media screen and (max-width: 768px) {
    .info-container {
        margin: 180px 20px 20px; /* Ensure consistent increased spacing for smaller screens */
        max-width: calc(100% - 40px);
    }
}

/* Further adjustments for extra-small screens (less than 576px) */
@media screen and (max-width: 576px) {
    .info-container {
        margin: 180px 20px 20px; /* Consistent spacing for the smallest screens */
    }

    /* Optional: Adjust font sizes if necessary for better readability on smaller screens */
    .info-container h1 {
        font-size: 1.5rem;
    }

    .info-container h2 {
        font-size: 1.3rem;
    }

    .info-container p,
    .info-container li {
        font-size: 0.9rem;
    }
}

/* Adjustments for specific devices */
/* iPhone SE */
@media screen and (max-width: 375px) {
    .info-container {
        margin-top: 110px; /* Adjusted top margin */
    }
}

/* iPhone XR, iPhone 12 Pro, iPhone 14 Pro Max */
@media screen and (min-width: 376px) and (max-width: 430px) {
    .info-container {
        margin-top: 110px; /* Adjusted top margin */
    }
}

/* Pixel 7, Samsung Galaxy S8+, Samsung Galaxy S20 Ultra */
@media screen and (min-width: 431px) and (max-width: 600px) {
    .info-container {
        margin-top: 110px; /* Adjusted top margin */
    }
}

/* iPad Mini */
@media screen and (min-width: 601px) and (max-width: 768px) {
    .info-container {
        margin-top: 110px; /* Adjusted top margin */
    }
}

/* iPad Air */
@media screen and (min-width: 769px) and (max-width: 820px) {
    .info-container {
        margin-top: 110px; /* Adjusted top margin */
        margin-left: 50px; /* Shift container slightly to the left */
        max-width: 700px; /* Reduce max-width for iPad Air */
    }
}

/* iPad Pro */
@media screen and (min-width: 1024px) and (max-width: 1366px) {
    .info-container {
        margin-top: 150px; /* Increase top margin for iPad Pro */
    }
}

/* Surface Pro 7 */
@media screen and (min-width: 912px) and (max-width: 1368px) {
    .info-container {
        margin-left: 150px; /* Adjust the margin-left to shift slightly to the left */
        max-width: 900px; /* Increase max-width for Surface Pro 7 */
    }
}

/* Nest Hub */
@media screen and (min-width: 1024px) and (max-width: 1024px) and (min-height: 600px) and (max-height: 600px) {
    .info-container {
        margin-left: 200px; /* Shift container slightly to the right */
    }
}

/* Nest Hub Max */
@media screen and (min-width: 1280px) and (max-width: 1280px) and (min-height: 800px) and (max-height: 800px) {
    .info-container {
        margin-left: 200px; /* Shift container slightly to the right */
    }
}

@media screen and (min-width: 1264px) {
    .info-container {
        margin-top: 120px;
        margin-left: 180px;
    }
}

/* Adjustments for Asus Zenbook Fold */
@media screen and (min-width: 1920px) and (max-width: 2048px) and (min-height: 1200px) {
    .info-container {
        max-width: 500px; /* Reduced max-width for Asus Zenbook Fold */
        margin-left: 150px; /* Shift container slightly to the left */
        margin-top: 20px; /* Adjust the margin-top to move the container and box up */
    }
}