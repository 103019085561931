/* ForgotPasswordPage.css */

.forgotpassword-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 400px;
    margin: 120px auto;
    padding: 30px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #ffffff;
    box-sizing: border-box;
}

.forgotpassword-header {
    font-size: 32px;
    margin-bottom: 30px;
    font-weight: bold;
}

.forgotpassword-input {
    width: 100%;
    padding: 15px;
    margin-bottom: 15px;
    font-size: 18px;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    background-color: #f8f8f8;
    transition: background-color 0.2s;
}

.forgotpassword-input:focus {
    background-color: #e8e8e8;
}

.forgotpassword-button {
    width: 100%;
    padding: 15px;
    font-size: 18px;
    background-color: #3897f0;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.forgotpassword-button:hover {
    background-color: #307abf;
}

.forgotpassword-links {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.forgotpassword-text {
    font-size: 16px;
}

.forgotpassword-text a {
    color: #3897f0;
    text-decoration: none;
    margin-left: 5px;
}

.forgotpassword-text a:hover {
    text-decoration: underline;
}

.message-info {
    font-size: 16px;
    color: green;
    margin-bottom: 20px;
}

.error-message {
    font-size: 16px;
    color: red;
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .forgotpassword-container {
        padding: 20px;
        margin-top: 150px;
        max-width: 90%;
    }

    .forgotpassword-input,
    .forgotpassword-button {
        padding: 10px;
        font-size: 16px;
    }

    .forgotpassword-header {
        font-size: 28px;
    }

    .forgotpassword-text {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .forgotpassword-container {
        margin-top: 180px;
        padding: 15px;
    }

    .forgotpassword-header {
        font-size: 24px;
    }

    .forgotpassword-input,
    .forgotpassword-button {
        font-size: 16px;
    }
}
