/* Navbar.css */

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

#navbar {
    display: flex;
    justify-content: space-between; /* Space between the home icon and the logo */
    align-items: center;
    background-color: #f4f7f9;
    font-family: 'Arial', sans-serif;
    color: #333;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    min-width: 320px;
    height: 60px; /* Set a fixed height to maintain the appearance */
}

.navbar-left, .navbar-center, .navbar-right {
    display: flex;
    align-items: center;
}

.navbar-left {
    justify-content: flex-start;
}

.navbar-center {
    justify-content: center;
    flex: 1; /* This will help center the content */
    margin-left: -180px; /* Adjust this value to shift the text slightly to the left */
}

.navbar-right {
    justify-content: flex-end;
}

.navbar-link {
    display: flex;
    align-items: center;
    text-decoration: none; /* Remove underline from link */
    color: inherit; /* Inherit color from parent */
    margin-right: 15px; /* Space between items */
}

.navbar-icon {
    height: 50px;  /* Ensure the height is consistent */
    width: auto;
    cursor: pointer;
    margin-right: -5px; /* Shift the HyroVault logo to the right */
}

.home-icon {
    height: 85px; /* Increase the height to make the icon larger */
    width: auto; /* Ensure it scales proportionally */
    fill: currentColor; /* Ensures the icon inherits the text color */
    margin-left: -15px; /* Shift the home icon to the left */
}

.sidebar-icon {
    font-size: 30px;
    margin-left: 15px; /* Space between the Home icon and the tri-bar icon */
    cursor: pointer;
}

.items-suggested-count {
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: #333;
    font-weight: bold; /* Make the "Items Shared" text bold */
}

.items-suggested-count span {
    font-weight: bold;
}

.aisle-number {
    background-color: #fff; /* White background */
    color: #000; /* Black text */
    font-family: 'Bebas Neue', sans-serif; /* Bebas Neue font */
    font-size: 2rem; /* Larger size */
    padding: 5px 10px;
    border: 2px solid #000; /* Black border */
    border-radius: 5px;
    margin-right: 10px; /* Space between the number and the text */
}

@media (max-width: 768px) {
    #navbar {
        padding: 10px; /* Adjust padding to fit smaller screens */
        justify-content: space-between; /* Ensure consistent spacing on smaller screens */
    }

    .navbar-left, .navbar-right {
        flex: 0 0 auto; /* Ensure flex properties are set for proper spacing */
    }

    .navbar-left {
        margin-left: 10px; /* Adjust margin to push Home icon further left */
    }

    .navbar-right {
        margin-right: 10px; /* Adjust margin to push HyroVault logo further right */
    }

    .navbar-center {
        margin-left: 0; /* Reset margin for smaller screens */
    }

    .items-suggested-count {
        justify-content: center;
        width: 100%; /* Ensure it takes full width on smaller screens */
        text-align: center; /* Center text on smaller screens */
    }
}

@media (min-width: 768px) {
    #navbar {
        flex-direction: row;
        justify-content: space-between; /* Space between the home icon and the logo on larger screens */
    }

    .navbar-right {
        margin-left: 0; /* Ensure no margin issues on larger screens */
    }
}

/* Specific adjustments for different devices */
@media (max-width: 375px) { /* iPhone SE */
    .navbar-center {
        margin-left: -50px; /* Adjust the value to shift the text to the left */
    }
    .home-icon {
        margin-left: -30px; /* Shift the home icon to the left */
    }
    .navbar-icon {
        margin-right: -30px; /* Shift the HyroVault logo to the right */
    }
    .aisle-number {
        font-size: 1.2rem; /* Smaller size for aisle-number */
        padding: 3px 8px; /* Adjust padding */
    }
}

@media (max-width: 414px) { /* iPhone XR, iPhone 12 Pro */
    .navbar-center {
        margin-left: -50px; /* Adjust the value to shift the text to the left */
    }
    .home-icon {
        margin-left: -30px; /* Shift the home icon to the left */
    }
    .navbar-icon {
        margin-right: -30px; /* Shift the HyroVault logo to the right */
    }
    .aisle-number {
        font-size: 1.2rem; /* Smaller size for aisle-number */
        padding: 3px 8px; /* Adjust padding */
    }
}

@media (max-width: 428px) { /* iPhone 14 Pro Max */
    .navbar-center {
        margin-left: -50px; /* Adjust the value to shift the text to the left */
    }
    .home-icon {
        margin-left: -30px; /* Shift the home icon to the left */
    }
    .navbar-icon {
        margin-right: -30px; /* Shift the HyroVault logo to the right */
    }
    .aisle-number {
        font-size: 1.2rem; /* Smaller size for aisle-number */
        padding: 3px 8px; /* Adjust padding */
    }
}

@media (max-width: 411px) { /* Pixel 7, Samsung Galaxy S8+, Samsung Galaxy S20 Ultra */
    .navbar-center {
        margin-left: -50px; /* Adjust the value to shift the text to the left */
    }
    .home-icon {
        margin-left: -30px; /* Shift the home icon to the left */
    }
    .navbar-icon {
        margin-right: -30px; /* Shift the HyroVault logo to the right */
    }
    .aisle-number {
        font-size: 1.2rem; /* Smaller size for aisle-number */
        padding: 3px 8px; /* Adjust padding */
    }
}

@media (max-width: 540px) { /* Surface Duo */
    .navbar-center {
        margin-left: -50px; /* Adjust the value to shift the text to the left */
    }
    .home-icon {
        margin-left: -30px; /* Shift the home icon to the left */
    }
    .navbar-icon {
        margin-right: -30px; /* Shift the HyroVault logo to the right */
    }
    .aisle-number {
        font-size: 1.2rem; /* Smaller size for aisle-number */
        padding: 3px 8px; /* Adjust padding */
    }
}

@media (max-width: 1536px) { /* Galaxy Z Fold 5 */
    .navbar-center {
        margin-left: -50px; /* Adjust the value to shift the text to the left */
    }
    .home-icon {
        margin-left: -30px; /* Shift the home icon to the left */
    }
    .navbar-icon {
        margin-right: -30px; /* Shift the HyroVault logo to the right */
    }
    .aisle-number {
        font-size: 1.2rem; /* Smaller size for aisle-number */
        padding: 3px 8px; /* Adjust padding */
    }
}

@media (max-width: 1080px) { /* Samsung Galaxy A51/71 */
    .navbar-center {
        margin-left: -50px; /* Adjust the value to shift the text to the left */
    }
    .home-icon {
        margin-left: -30px; /* Shift the home icon to the left */
    }
    .navbar-icon {
        margin-right: -30px; /* Shift the HyroVault logo to the right */
    }
    .aisle-number {
        font-size: 1.2rem; /* Smaller size for aisle-number */
        padding: 3px 8px; /* Adjust padding */
    }
}

@media (min-width: 1264px) { /* Adjust for screens 1264px and above */
    .home-icon {
        margin-left: 0; /* Shift the home icon slightly to the right */
    }

    .navbar-icon {
        margin-right: -10px; /* Shift the HyroVault logo slightly to the left */
    }

    .aisle-number {
        font-size: 2rem; /* Ensure the size remains large on larger screens */
        padding: 5px 10px; /* Original padding for larger screens */
    }
}
