/* ViewReports.css */

.view-reports-container {
    padding: 20px;
    background-color: #f5f6f7; /* Light grey background */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px; /* Adjust as per your layout */
    margin: auto;
  }
  
  .view-reports-container h2 {
    color: #1c1e21; /* Dark text for contrast */
    margin-bottom: 20px;
  }
  
  .reports-list {
    list-style: none;
    padding: 0;
  }
  
  .report-item {
    background-color: #ffffff; /* White background for each item */
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #ccd0d5; /* Light border for definition */
  }
  
  .report-item p {
    margin: 5px 0;
    color: #4b4f56; /* Slightly muted text */
  }
  
  .report-item button {
    background-color: #1877f2; /* Meta's primary blue */
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    margin-right: 10px;
  }
  
  .report-item button:hover {
    background-color: #1654a1; /* Darker shade on hover */
  }
  

  