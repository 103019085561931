/* ContactForm.css */

.contact-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    padding-top: 70px;
    padding-bottom: 30px;
    width: 100%;
    overflow-x: hidden;
}

.card-container {
    width: 100%;
    max-width: 500px;
    overflow-x: hidden;
}

.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.homepage-logo {
    width: 120px;
    height: auto;
}

.card-container {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 12px;
    width: 100%;
    max-width: 500px;
}

.title {
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 8px;
}

.title-link {
    text-decoration: none;
    color: inherit;
}

.input-field {
    margin-bottom: 6px;
    border-radius: 5px;
    font-size: 1rem;
}

.math-captcha .MuiInputBase-input {
    text-align: center;
}

.buttons-container {
    display: flex;
    justify-content: center;
    margin-top: 8px;
    gap: 3px;
}

.action-button {
    padding: 2px 6px;
    font-size: 0.8rem;
    font-weight: 500;
}

.success-message {
    font-size: 0.85rem;
    margin-top: 10px;
    text-align: center;
    color: blue;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
   .contact-page-container {
        margin-left: 0;
        padding: 0;
        margin-top: 110px;
    }

   .card-container {
        max-width: 90%;
    }
}

@media (max-width: 480px) {
   .contact-page-container {
        margin-top: 90px;
    }

   .card-container {
        max-width: 90%;
    }
}

/* Adjustments for iPad Air */
@media (min-width: 769px) and (max-width: 820px) {
   .contact-page-container {
        margin-top: 70px;
    }
}

/* Adjustments for Surface Pro 7 */
@media (min-width: 821px) and (max-width: 912px) {
    .contact-page-container {
      margin-top: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 0;
      padding-right: 0;
    }
  
    .card-container {
      max-width: 500px;
      margin: 0;
    }
  }

/* Adjustments for Surface Duo */
@media (min-width: 913px) and (max-width: 1368px) {
   .contact-page-container {
        margin-top: 90px;
        margin-left: auto;
        margin-right: auto;
    }
}

/* Adjustments for Asus Zenbook Fold */
@media screen and (min-width: 1920px) and (max-width: 2048px) and (min-height: 1200px) {
   .contact-page-container {
        justify-content: center;
        padding-left: 0;
    }

   .card-container {
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 1024px) {
   .contact-page-container {
        margin-left: 20%;
        margin-top: 30px;
    }

   .card-container {
        max-width: 500px;
    }
}

@media (min-width: 1300px) {
   .contact-page-container {
        margin-left: 240px;
    }

   .card-container {
        max-width: 500px;
    }
}

@media (min-width: 1440px) {
   .contact-page-container {
        margin-left: 30%;
        margin-top: 30px;
    }

   .card-container {
        max-width: 500px;
    }
}

@media (min-width: 1920px) {
   .contact-page-container {
        margin-left: 40%;
        margin-top: 30px;
    }

   .card-container {
        max-width: 500px;
    }
}

/* Center the container on specific devices */
/* iPhone SE */
@media screen and (max-width: 375px) {
    .contact-page-container {
         margin-left: auto;
         margin-right: auto;
     }
 
    .card-container {
         max-width: 85%; /* Adjusted max-width to reduce the container size */
         padding: 6px; /* Adjusted padding */
     }
 
    .input-field {
         font-size: 0.7rem; /* Reduced font size for input fields */
     }
 
    .action-button {
         font-size: 0.6rem; /* Reduced font size for buttons */
         padding: 2px 4px; /* Adjusted padding */
     }
 }

/* iPhone XR, iPhone 12 Pro, iPhone 14 Pro Max */
@media screen and (min-width: 376px) and (max-width: 430px) {
   .contact-page-container {
        margin-left: auto;
        margin-right: auto;
    }
}

/* Pixel 7, Samsung Galaxy S8+, Samsung Galaxy S20 Ultra */
@media screen and (min-width: 431px) and (max-width: 600px) {
   .contact-page-container {
        margin-left: auto;
        margin-right: auto;
    }
}

/* iPad Mini */
@media screen and (min-width: 601px) and (max-width: 768px) {
   .contact-page-container {
        margin-left: auto;
        margin-right: auto;
    }
}

/* iPad Air */
@media screen and (min-width: 769px) and (max-width: 820px) {
   .contact-page-container {
        margin-left: auto;
        margin-right: auto;
    }
}

/* Surface Duo */
@media screen and (min-width: 821px) and (max-width: 1368px) {
   .contact-page-container {
        margin-left: auto;
        margin-right: auto;
    }
}

/* Galaxy Z Fold 5, Samsung Galaxy A51/71, Nest Hub, Nest Hub Max */
@media screen and (min-width: 1369px) and (max-width: 1920px) {
   .contact-page-container {
        margin-left: auto;
        margin-right: auto;
    }
}

/* Adjustments for iPad Pro */
@media screen and (min-width: 1024px) and (max-width: 1366px) {
   .contact-page-container {
        margin-left: 150px;
        margin-right: auto;
    }
}

/* Nest Hub */
@media screen and (min-width: 1024px) and (max-width: 1024px) and (min-height: 600px) and (max-height: 600px) {
   .contact-page-container {
        margin-left: 150px;
        margin-right: auto;
    }
}

/* Nest Hub Max */
@media screen and (min-width: 1280px) and (max-width: 1280px) and (min-height: 800px) and (max-height: 800px) {
   .contact-page-container {
        margin-left: 200px;
        margin-right: auto;
    }
}

@media (min-width: 1300px) {
    .contact-page-container {
          margin-left: 180px; /* Increased margin-left to shift further to the right */
      }
  
    .card-container {
          max-width: 900px;
      }
}

