/* Footer.css */

.app-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f7f9;
  color: #333;
  padding: 10px 20px;
  width: 100%;
  text-align: right;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  font-family: 'Roboto', sans-serif;
}

.footer-alert {
  color: #333;
  font-size: 14px;
  text-align: center;
  flex-grow: 1;
}

.footer-alert span {
  display: inline-block;
  margin-left: 10px; /* Adjust the value as needed */
}

.footer-links {
  display: flex;
  justify-content: flex-end;
  width: auto;
}

.footer-link {
  color: #333;
  font-size: 14px;
  padding: 0 10px;
  text-decoration: none;
}

.footer-link:hover {
  color: #007bff;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .app-footer {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .footer-links {
    margin-top: 10px;
    justify-content: center;
  }
}

@media (min-width: 769px) {
  .app-footer {
    flex-direction: row;
    justify-content: space-between;
  }

  .footer-links {
    margin-right: 0;
  }
}

/* Specific adjustments for different devices */
@media (max-width: 375px) { /* iPhone SE */
  .footer-alert span {
    margin-left: 5px;
  }
}

@media (max-width: 414px) { /* iPhone XR, iPhone 12 Pro */
  .footer-alert span {
    margin-left: 10px;
  }
}

@media (max-width: 428px) { /* iPhone 14 Pro Max */
  .footer-alert span {
    margin-left: 15px;
  }
}

@media (max-width: 411px) { /* Pixel 7, Samsung Galaxy S8+, Samsung Galaxy S20 Ultra */
  .footer-alert span {
    margin-left: 10px;
  }
}

@media (max-width: 768px) { /* iPad Mini, Samsung Galaxy A51/71 */
  .footer-alert span {
    margin-left: 15px;
  }
}

@media (max-width: 540px) { /* Surface Duo */
  .footer-alert span {
    margin-left: 10px;
  }
}

@media (max-width: 1536px) { /* Galaxy Z Fold 5 */
  .footer-alert span {
    margin-left: 20px;
  }
}

/* Adjustments for iPad Air (820px width) */
@media only screen 
  and (min-device-width: 820px) 
  and (max-device-width: 820px) 
  and (-webkit-min-device-pixel-ratio: 2) {
  .footer-alert span {
    margin-left: 25px; /* Adjust the value as needed */
  }
}

/* Adjustments for iPad Pro (1024px width) */
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 2) {
  .footer-alert span {
    margin-left: 25px; /* Adjust the value as needed */
  }
}

/* Adjustments for Surface Pro 7 (912px width) */
@media only screen 
  and (min-device-width: 912px) 
  and (max-device-width: 912px) 
  and (-webkit-min-device-pixel-ratio: 1.25) {
  .footer-alert span {
    margin-left: 25px; /* Adjust the value as needed */
  }
}
