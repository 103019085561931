/* LoginPage.css */

.login-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    padding-top: 70px; /* Adjusted padding to make room for larger logo */
    padding-bottom: 30px; /* Added padding to avoid collision with footer */
    width: 100%;
  }
  
  .card-container {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.15);
    border-radius: 10px; /* Reduced border-radius */
    padding: 12px; /* Further reduced padding */
    width: 100%;
    max-width: 500px; /* Increased container width */
  }
  
  .login-header {
    font-size: 1.5rem; /* Adjusted size of the title text */
    font-weight: bold;
    text-align: center;
    margin-bottom: 8px; /* Adjusted bottom margin */
  }
  
  .error-message {
    font-size: 0.85rem; /* Adjusted font size for error messages */
    text-align: center;
  }
  
  .login-input {
    margin-bottom: 6px; /* Adjusted bottom margin */
    border-radius: 5px;
    font-size: 1rem; /* Adjusted font size for input fields */
  }
  
  .login-button {
    margin-top: 8px; /* Adjusted top margin */
    padding: 10px 0; /* Adjusted padding */
    font-size: 0.8rem; /* Adjusted font size */
    font-weight: 500;
  }
  
  .login-links {
    margin-top: 8px; /* Adjusted top margin */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .forgot-link {
    margin-bottom: 10px;
    color: #3897f0;
    text-decoration: none;
  }
  
  .signup-text {
    font-size: 16px;
  }
  
  .signup-text a {
    color: #3897f0;
    text-decoration: none;
    margin-left: 5px;
  }
  
  .signup-text a:hover {
    text-decoration: underline;
  }
  
  /* Adjustments for smaller screens */
  @media (max-width: 768px) {
    .login-page-container {
      margin-left: 0;
      padding: 0;
      margin-top: 50px; /* Added margin-top for smaller screens */
    }
  
    .card-container {
      max-width: 90%;
    }
  }
  
  @media (max-width: 480px) {
    .login-page-container {
      margin-top: 90px; /* Further adjusted margin-top for very small screens */
    }
  
    .card-container {
      max-width: 90%;
    }
  }
  
  /* Add a media query for larger screens */
  @media (min-width: 1024px) {
    .login-page-container {
      margin-left: 20%; /* Shift the container to the right */
      margin-top: 30px; /* Reduced margin-top for larger screens */
    }
  
    .card-container {
      max-width: 500px; /* Increased width of the container */
    }
  }
  
  @media (min-width: 1440px) {
    .login-page-container {
      margin-left: 30%; /* Shift the container further to the right */
      margin-top: 30px; /* Reduced margin-top for larger screens */
    }
  
    .card-container {
      max-width: 500px; /* Increased width of the container */
    }
  }
  
  @media (min-width: 1920px) {
    .login-page-container {
      margin-left: 40%; /* Shift the container even further to the right */
      margin-top: 30px; /* Reduced margin-top for larger screens */
    }
  
    .card-container {
      max-width: 500px; /* Increased width of the container */
    }
  }
  