/* SignUpPage.css */

.signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 400px;
    margin: 80px auto; /* Original top margin before adjustments */
    padding: 30px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #ffffff;
    box-sizing: border-box;
}

.signup-header {
    font-size: 32px;
    margin-bottom: 30px;
    font-weight: bold;
}

.signup-input {
    width: 100%;
    padding: 15px;
    margin-bottom: 15px;
    font-size: 18px;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    background-color: #f8f8f8;
    transition: background-color 0.2s;
}

.signup-input:focus {
    background-color: #e8e8e8;
}

.signup-button {
    width: 100%;
    padding: 15px;
    font-size: 18px;
    background-color: #3897f0;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.signup-button:hover {
    background-color: #307abf;
}

.signup-links {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.signup-text {
    font-size: 16px;
}

.signup-text a {
    color: #3897f0;
    text-decoration: none;
    margin-left: 5px;
}

.signup-text a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .signup-container {
        margin-top: 150px; /* Increased top margin for tablet screens */
        margin-bottom: 20px; /* Adjusted bottom margin if needed */
        padding: 20px;
    }

    .signup-header {
        font-size: 24px;
    }

    .signup-input,
    .signup-button {
        font-size: 16px;
    }
}

/* Adjusting font size within media queries for mobile screens in SignUpPage.css */

@media (max-width: 480px) {
    .signup-container {
        margin-top: 180px; /* Further increased top margin for mobile screens */
        padding: 15px;
    }

    .signup-header {
        font-size: 22px; /* No change here, just for context */
    }

    .signup-input,
    .signup-button {
        font-size: 16px; /* Adjusted font size to prevent zoom */
    }
}

/* Adjustments for large screens */
@media (min-width: 1024px) {
    .signup-container {
        margin-left: 250px; /* This keeps the container aligned to the right */
        margin-right: 10%; /* Adjusts the spacing from the right, effectively shifting it to the right */
        max-width: 400px; /* Keeps the max-width as before for consistency */
    }
}