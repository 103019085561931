/* FitsBoard.css */

.fitsBoardContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 250px);
    max-width: 600px;
    box-sizing: border-box;
}

.contentContainer {
    background-color: #fff;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0,0,0,0.15);
    width: 100%;
    margin-bottom: 40px;
}

.form-input, .form-textarea {
    width: 100%;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 10px;
    border: 2px solid #eaeaea;
    background-color: #ffffff;
    font-size: 16px;
    color: #000000;
    font-family: 'Arial', sans-serif;
    box-sizing: border-box;
}

.form-textarea {
    height: 150px;
    resize: vertical;
}

.form-input:focus, .form-textarea:focus {
    outline: none;
    border-color: #007bff;
    color: #000000;
    background-color: #ffffff;
}

/* Placeholder text styles */
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #777;
    opacity: 1; /* Firefox */
    font-size: 16px; /* Match font-size with other input types */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #777;
    font-size: 16px; /* Match font-size with other input types */
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: #777;
    font-size: 16px; /* Match font-size with other input types */
}

.dropzone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    border-radius: 15px;
    border: 2px dashed #007bff;
    background-color: #fafafa;
    text-align: center;
    cursor: pointer;
    margin-bottom: 20px;
}

.dropzone-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.upload-icon, .images-icon {
    color: #007bff;
    margin: 20px 0;
}

.uploaded-image-preview {
    width: auto;
    max-height: 250px;
    object-fit: contain;
    border-radius: 10px;
    margin-top: 20px;
}

.form-button {
    width: 100%;
    padding: 15px 0;
    border-radius: 10px;
    background-color: #007bff;
    color: white;
    font-size: 18px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.form-button:hover {
    background-color: #0056b3;
}

.submittedContent {
    background-color: #fff;
    margin-bottom: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    width: 100%;
}

.imageContainer {
    flex-shrink: 0;
    width: 120px;
    height: 120px;
    overflow: hidden;
    margin-right: 15px;
    border-radius: 10px;
    cursor: pointer;
}

.urlPreviewImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.deleteButton {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    color: #ff4545;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.deleteButton:hover {
    color: #ff0000;
}

.submittedItemName, .submittedDescription {
    margin: 5px 0;
    color: #333;
}

.submittedItemName {
    font-weight: bold;
}

.form-select {
    width: 100%;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 10px;
    border: 2px solid #eaeaea;
    background-color: #ffffff;
    font-size: 16px;
    color: #000000;
    box-sizing: border-box;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}

.form-select:focus {
    outline: none;
    border-color: #007bff;
    background-color: #ffffff;
    color: #000000;
}

/* Style the arrow inside the select element: */
.form-select::-ms-expand {
    display: none; /* Remove default arrow in IE */
}

/* Add custom arrow for browsers that support the pseudo-element */
/* Note: It's not possible to style the arrow for IE 11+ and Edge */
.form-select {
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M6%209L11%204L1%204L6%209Z%22%20fill%3D%22%23007BFF%22%3E%3C/path%3E%3C/svg%3E');
    background-repeat: no-repeat;
    background-position: right 15px center;
    background-size: 12px;
}

.form-select.placeholder {
    color: #777; /* Grey color, same as your placeholder text */
}

/* Style for options */
.form-select option {
    color: black; /* Default text color for options */
}

/* Style to make disabled option (placeholder) grey */
.form-select option:disabled {
    color: grey;
}

.age-range-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.age-input {
    width: 48%; /* Adjusts the width to fit side by side */
}

.temperature-section, .dress-code-section {
    margin-bottom: 30px; /* Increase this value for more space */
}

/* Ensure all items are on one line and aligned properly */
.temperature-row, .dress-code-row {
    display: flex;
    justify-content: flex-start; /* Align checkboxes to the start of the container */
    gap: 15px; /* Adjust space between the checkboxes */
}

/* Adjust label styles to ensure consistency and proper spacing */
.temperature-row label, .dress-code-row label {
    font-size: 16px; /* Ensure text is legible */
    display: flex;
    align-items: center; /* Align the text with the checkbox */
    cursor: pointer; /* Change cursor to pointer to indicate clickable area */
}

.submissionCountContainer {
    margin-bottom: 20px;
    text-align: center;
}

/* Additional CSS to shift the FitsBoard content to the right for larger screens */
@media (min-width: 1264px) {
    .fitsBoardContainer {
        margin-left: 300px; /* Adjust the value to shift further to the right */
    }
}
