/* ProfilePage.css */

body {
    background-color: #F9F9F9; /* Soft gray background */
}

.profile-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers children horizontally */
    padding: 20px;
    box-sizing: border-box;
    /* Remove margin-left from the base class */
}

.profile-header {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    width: 100%; /* Full width to center within the parent container */
    margin-top: 80px; /* Increased spacing */
    /* Remove margin-left from the base class */
}

.profile-picture-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Remove margin-left from the base class */
}

.profile-picture {
    font-size: 150px;
    color: #757575; /* Grey color for the Material UI icon */
    /* Remove margin-left from the base class */
}

.user-info {
    text-align: center;
}

.user-name {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0.5rem 0;
}

.post-count {
    color: #777;
    /* Remove margin-left from the base class */
}

.explore-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Set three images per row */
    gap: 20px;
    width: 100%; /* Make grid take full width of the available space */
    margin: auto;
    margin-top: 40px;
    /* Remove margin-left from the base class */
}

.explore-item img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

@media screen and (max-width: 768px) {
    .profile-container,
    .profile-header,
    .profile-picture-container,
    .profile-picture,
    .user-info,
    .post-count,
    .explore-grid {
        margin-left: 0; /* Reset any specific margins for smaller screens */
    }

    .profile-picture {
        font-size: 80px; /* Smaller icon size on smaller screens */
    }

    .user-name {
        font-size: 1.2rem; /* Smaller font size on smaller screens */
    }

    .explore-grid {
        grid-template-columns: repeat(3, 1fr); /* Adjust grid for smaller screens */
        gap: 10px; /* Adjust gap for smaller screens */
        margin-top: 20px; /* Adjust margin-top for smaller screens */
    }
}

@media screen and (max-width: 768px) {
    .profile-container {
        padding: 20px 10px; /* Adjusted padding for smaller screens */
    }

    .profile-header {
        /* Ensure the header takes the full width and is centered */
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 80px; /* Add more space between the Navbar and profile header */
    }

    .profile-picture-container {
        align-items: center; /* Center align to match the grid */
        margin-top: 8px; /* Adjusted top margin to create more space */
    }

    .profile-picture {
        font-size: 42px; /* Reduced size for more vertical space */
        margin-top: 30px; /* Add space above the profile picture */
    }

    .user-info {
        text-align: center;
        padding-top: 8px; /* Added padding to create more space */
    }

    .user-name {
        font-size: 1.2rem; /* Smaller font size for smaller screens */
    }

    .post-count {
        font-size: 1rem; /* Smaller font size for smaller screens */
        padding-top: 4px; /* Added padding to create more space */
    }

    .explore-grid {
        grid-template-columns: repeat(3, 1fr); /* Adjust grid for smaller screens */
        gap: 10px; /* Adjust gap for smaller screens */
        margin-top: 20px; /* Adjust margin-top for smaller screens */
    }
}

@media screen and (min-width: 1200px) {
    .profile-container {
        /* Adjusted to shift to the right */
        padding-left: calc((100vw - 1200px) / 2 + 40px);
        margin-left:  50px; /* Shifts the profile picture container to the right */
    }

    .profile-header {
        margin-left:  50px; /* Shifts the profile picture container to the right */
    }

    .profile-picture-container {
        margin-left:  50px; /* Shifts the profile picture container to the right */
    }

    .profile-picture {
        margin-left:  50px; /* Shifts the profile picture container to the right */
    }

    .user-info {
        margin-left:  50px; /* Shifts the user info to the right */
      }

    .explore-grid {
        /* Adjusted to shift to the right */
        margin-left: calc((100vw - 1200px) / 2 + 20px); 
    }
    
}