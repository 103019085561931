/* Fits.css */

.fits-form-container {
    min-height: auto;
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    /* Keep margin-left at default for large screens, adjust if necessary */
}

.fits-form {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin: auto;
    /* Set default max-width for larger screens */
    max-width: 800px;
}

.generate-button, .clear-button {
    margin-top: 20px;
}

.suggestions-list {
    margin-top: 20px;
    text-align: left;
}

.suggestions-list .MuiTypography-root {
    color: #000; /* Change from #1a0dab to standard black */
    cursor: default; /* Change from pointer to default */
    transition: none; /* Remove text-decoration transition */
}

/* Remove hover effect */
.suggestions-list .MuiTypography-root:hover {
    text-decoration: none; /* Ensure no underline appears on hover */
}

/* Responsive adjustments for medium devices */
@media screen and (max-width: 992px) {
    .fits-form-container {
        margin-top: 120px;
        /* Ensure container does not overlap with sidebar */
        margin-left: 0;
    }
    .fits-form {
        /* Adjust width to use more of the available screen */
        max-width: calc(100% - 40px);
    }
}

/* Adjustments for smaller screens */
@media screen and (max-width: 768px) {
    .fits-form-container {
        margin-top: 120px;
    }
    .fits-form {
        max-width: calc(100% - 40px); /* Adjust for smaller devices */
    }
}

/* Further adjustments for very small screens */
@media screen and (max-width: 576px) {
    .fits-form-container {
        margin-top: 120px;
    }
    .fits-form {
        max-width: calc(100% - 20px); /* Optimize for very small devices */
    }
}

/* Additional adjustments for larger screens */
@media screen and (min-width: 992px) {
    .fits-form-container {
        /* Increase left margin to avoid collision with the sidebar */
        /* Adjust this value to ensure the form does not overlap with the sidebar */
        margin-left: calc((100vw - 1392px) / 2 + 120px); /* You may need to adjust based on your layout */
    }
    .fits-form {
        /* Ensure the form is properly sized - adjust as needed based on your design */
        max-width: calc(1392px - 300px); /* Adjust if your sidebar width is different */
    }
}
